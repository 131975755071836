import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/Layout"
//import MailDatenschutz from "../components/MailDatenschutz"
import Mailto from 'reactv16-mailto';
import { Helmet } from 'react-helmet'

import { Jumbotron,  Container, Row, Col } from "react-bootstrap"


import Apache_logo from "../images/Apache_logo.svg"
import Nginx_logo from "../images/Nginx_logo.svg"
import Debian_logo from "../images/Debian_logo.svg"
import LAMP_logo from "../images/LAMP_logo.svg"
import Mariadb_logo from "../images/Mariadb_logo.svg"
import PHP_logo from "../images/PHP_logo.svg"
import Python_logo from "../images/Python_logo.svg"
import TYPO3_logo from "../images/TYPO3_logo.svg"
import Ubuntu_logo from "../images/Ubuntu_logo.svg"
import WordPress_logo from "../images/WordPress_logo.svg"
import Laravel_logo from "../images/Laravel_logo.svg"
import Zend_logo from "../images/Zend_logo.svg"

import "../styles/Globals.scss"

export default function Home() {
  return (
    <Layout>
      <Helmet defer={false}>
        <title defer={false}>Webhosting im LAMP | WH Serverpark</title>
      </Helmet>

      <Jumbotron fluid>
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
              <h1>LAMP Stack</h1>
              <p>Shared Web-Hosting mit PHP und MariaDB</p>
              <p>
                Wir bieten Ihnen individualisierte Webhosting Packet auf unseren
                Shared Hosting Server an. Dabei werden wir für Sie eine optimale
                Hostingumgebung aufbauen um Ihnen ein sorgloses arbeiten mit
                Ihrer Webapplikation zu ermöglichen. Natürlich beraten wir Sie
                persönlich, denn wir sind der Meinung das jede Webapplikation
                einzigartig ist.
              </p>
              <p>
              <Mailto className="btn btn-primary" email='info@wh-serverpark.com' obfuscate={true}  headers={{subject:'LAMP Stack'}}>Kontakt aufnehmen</Mailto>

              </p>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 4, order: 2 }}
              className="text-md-right"
            >
              <img src={LAMP_logo} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container flex>
        <Row>
          <Col>
            <p>
              Manchmal reicht "von der Stange" eben nicht mehr aus, deswegen bieten wir optimierte Lösungen für Ihre Webapplikaitonen an, genau mit den Modulen und Anpassungen die Ihre Anwendung benötigt.
            </p>
            <div className="d-flex flex-nowrap  my-4">
              <div class="flex-shringk-1"><img src={Debian_logo} alt="" class="img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Ubuntu_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Apache_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Nginx_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Mariadb_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={PHP_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Python_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={TYPO3_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={WordPress_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Laravel_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Zend_logo} alt="" class=" img-fluid"></img></div>
            </div>

            
            <p>
              Egal welche Lösung Sie bevorzugen, auf unserem
              hardwareunabhängigen und hochverfügbarem Servercluster ist immer
              genug Power für Ihre Anwendung vorhanden. Möchten Sie Mehr Wissen?
            </p>
            <p>
              Gerne beantworten wir Ihre Hosting Anfragen direkt in einem
              persönlichen Gespräch oder per <Mailto email='info@wh-serverpark.com' obfuscate={true}  headers={{subject:'LAMP Stack'}}>E-Mail</Mailto>.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
